<template>
  <v-textarea
    v-bind="$attrs"
    full-width
    auto-grow
    background-color="white"
    rows="1"
    v-on="$listeners"
  >
  </v-textarea>
</template>
<script>
export default {
  name: 'STextarea',
  inheritAttrs: false,
  setup() {},
};
</script>
